import { makeAutoObservable, runInAction } from 'mobx';
import localStorageService from '@services/local-storage-service.ts';

class NavSidebarStore {
	private _open = false;
	private _minimal = false;

	constructor() {
		makeAutoObservable(this);
	}

	setOpen(isOpen: boolean) {
		runInAction(() => {
			this._open = isOpen;
		});
	}

	toggleOpen() {
		runInAction(() => {
			this._open = !this._open;
		});
	}

	setMinimal(minimal: boolean) {
		runInAction(() => {
			this._minimal = minimal;
		});

		localStorageService.set(`tgs-sb-m`, minimal ? 'true' : 'false');
	}

	restoreFromLocalStorage(userId?: number) {
		runInAction(() => {
			this._minimal =
				localStorageService.get(`tgs-sb-m`, userId) === 'true';
		});
	}

	get open() {
		return this._open;
	}

	get minimal() {
		return this._minimal;
	}
}

const navSidebarStore = new NavSidebarStore();
export default navSidebarStore;
