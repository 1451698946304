import { z } from 'zod';

export enum UserRole {
	ADMIN = 'admin',
	EDITOR = 'editor',
	VIEWER = 'viewer',
}

export enum UserPermission {
	EDIT_TAG = 'edit-tag',
	LIST_USERS = 'list-users',
	EDIT_USER_ROLE = 'edit-user-role',
	EDIT_GAME_CREDENTIALS = 'edit-game-creds',
	EDIT_PLACE = 'edit-place',
	VIEW_CODE_LIBRARY = 'view-code-lib',
}

export interface User {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	created: string; // Date string
	role: UserRole;
	last_active: string; // Date string
	permissions?: UserPermission[];
}

const filterPermissions = (val: unknown) => {
	if (Array.isArray(val) && val.every((v) => typeof v === 'string')) {
		const permissions = Object.values(UserPermission) as string[];
		return val.filter((per) =>
			permissions.includes(per)
		) as UserPermission[];
	}

	return undefined;
};

const UserPermissionSchema: z.ZodType<UserPermission[]> = z
	.any()
	.transform(filterPermissions)
	.pipe(z.array(z.nativeEnum(UserPermission)));

export const UserSchema: z.ZodType<User> = z.object({
	id: z.number(),
	first_name: z.string(),
	last_name: z.string(),
	email: z.string(),
	created: z.string(),
	role: z.nativeEnum(UserRole),
	last_active: z.string(),
	permissions: UserPermissionSchema.optional(),
});

export const UserListSchema: z.ZodType<User[]> = z.array(UserSchema);
