import { makeAutoObservable, runInAction } from 'mobx';
import localStorageService from '@services/local-storage-service.ts';
import { LocalStorageKey } from '@/enums/local-storage-types.ts';
import authStore from '@store/auth-store.ts';

class ThemeStore {
	_theme: 'light' | 'dark' = 'light';

	constructor() {
		makeAutoObservable(this);
		if (this._theme === 'dark') {
			document.documentElement.classList.add('dark');
		}
	}

	get lightTheme() {
		return this._theme === 'light';
	}

	get darkTheme() {
		return this._theme === 'dark';
	}

	toggleTheme() {
		const newTheme = this._theme === 'light' ? 'dark' : 'light';
		localStorageService.set(
			LocalStorageKey.ColorTheme,
			newTheme,
			authStore.user?.id
		);
		runInAction(() => {
			this._theme = newTheme;
		});

		if (newTheme === 'light') {
			document.documentElement.classList.remove('dark');
		} else {
			document.documentElement.classList.add('dark');
		}
	}

	setTheme(theme: 'light' | 'dark') {
		localStorageService.set(
			LocalStorageKey.ColorTheme,
			theme,
			authStore.user?.id
		);
		runInAction(() => {
			this._theme = theme;
		});

		if (theme === 'light') {
			document.documentElement.classList.remove('dark');
		} else {
			document.documentElement.classList.add('dark');
		}
	}

	restoreFromLocalStorage(userId?: number) {
		this.setTheme(
			localStorageService.get(LocalStorageKey.ColorTheme, userId) ===
				'dark'
				? 'dark'
				: 'light'
		);
	}

	setToDefault() {
		runInAction(() => {
			this._theme = 'light';
		});
		document.documentElement.classList.remove('dark');
	}
}

const themeStore = new ThemeStore();
export default themeStore;
