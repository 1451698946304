import posthog from 'posthog-js';
import { User } from '@/schemas/user-schema.ts';
import { PostHogEvent } from '@/enums/posthog-event.ts';
import authStore from '@store/auth-store.ts';

const POSTHOG_ENABLED = import.meta.env.VITE_POSTHOG_ENABLED === 'true';
const DEFAULT_IDENTIFY_RETRIES = 10;
const FILTERED_USERS = ['filip.wennerdahl@thegang.io', 'henry@thegang.io'];

class PostHogService {
	private _initialized = false;
	private _identifyRetries = DEFAULT_IDENTIFY_RETRIES;

	init() {
		if (!POSTHOG_ENABLED) {
			return;
		}

		posthog.init('phc_GaCBL1yz3q9ccKnJjLmZRUpWe5ZhewczMYJauha0FY3', {
			api_host: 'https://eu.i.posthog.com',
			autocapture: false,
			capture_pageview: false,
			capture_pageleave: false,
			disable_surveys: true,
			loaded: () => {
				this._initialized = true;
			},
		});
		this._identifyRetries = DEFAULT_IDENTIFY_RETRIES;
	}

	async identify(user: User): Promise<boolean> {
		if (!POSTHOG_ENABLED) {
			return true;
		}

		if (this._identifyRetries <= 0) {
			this._identifyRetries = DEFAULT_IDENTIFY_RETRIES;
			return false;
		}

		if (this._initialized) {
			posthog.identify(`${user.id}`, {
				email: user.email,
				name: `${user.first_name} ${user.last_name}`,
			});
			this._identifyRetries = DEFAULT_IDENTIFY_RETRIES;
			return true;
		} else {
			await new Promise((resolve) => setTimeout(resolve, 250));
			this._identifyRetries--;
			return this.identify(user);
		}
	}

	reset() {
		if (!POSTHOG_ENABLED) {
			return true;
		}

		posthog.reset();
	}

	capture(event: PostHogEvent) {
		if (
			!POSTHOG_ENABLED ||
			FILTERED_USERS.includes(authStore.user?.email ?? '')
		) {
			return true;
		}

		const { type, ...copiedEventWithoutType } = event;
		posthog.capture(type, copiedEventWithoutType);
	}
}

const postHogService = new PostHogService();
export default postHogService;
