import { LocalStorageKeyType } from '@/enums/local-storage-types.ts';

class LocalStorageService {
	get(key: LocalStorageKeyType, userId?: number) {
		return localStorage.getItem(userId ? `${userId}-${key}` : key);
	}

	set(key: LocalStorageKeyType, value: string, userId?: number) {
		localStorage.setItem(userId ? `${userId}-${key}` : key, value);
	}
}

const localStorageService = new LocalStorageService();
export default localStorageService;
